import React, { ReactElement } from "react";
import MainBanner from "@components/topchoicehomewarranty/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import TopChoiceHomeWarrantyWrapper from "@components/layouts/topchoicehomewarrantyWrapper";

const OffersList = dynamic(() => import("@components/shared/offersList"));

const Benefits = dynamic(
    () => import("@components/topchoicehomewarranty/benefits"),
);
import WhatBenefits from "@components/topchoicehomewarranty/whatBenefits";
import HowIsHomeWarranty from "@components/topchoicehomewarranty/howIsHomeWarranty";
import WhatIsHomeWarranty from "@components/topchoicehomewarranty/whatIsHomeWarranty";
import { DomainForm } from "src/api";
import NewJerseyDisclaimer from "@components/shared/newJerseyDisclaimer";

export default function NextAutoWarrantyHome({
    form,
}: {
    form: DomainForm;
}): ReactElement {
    const { domain } = useDomainContext();

    return (
        <TopChoiceHomeWarrantyWrapper>
            <MainBanner form={form} />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>

            <WhatIsHomeWarranty />
            <HowIsHomeWarranty />
            <WhatBenefits />
            <Benefits />
            <NewJerseyDisclaimer backGround="#f7f7f7" />
        </TopChoiceHomeWarrantyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
